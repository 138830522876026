.absolute_centered {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}
section{
    margin: 2em auto 0;
}
.text-center{
    text-align: center;
}
.date {
    background: url(/img/icons/date.png) no-repeat 0 50%;
    padding-left: 24px;
}
a,a:hover{
    color: $color_primary;
}
div.clr {
    width: 100%;
    clear: both;
}
:root {
    --swiper-theme-color: #b51726;
}
@media (min-width: 577px) {
    .mobile_show {
        display: none !important;
    }
}
@media screen and (max-width: 576px) {
    .mobile_hide {
        display: none !important;
    }
}