.prices {
    padding: 20px 0 30px;
    background-color: transparent;
    position: relative;
}

.prices__title {
    margin-top: 0;
}

.prices__list__main {
    cursor: pointer;
    width: 100%;
    background: #e0e0e0;
    padding: 10px 35px;
    box-shadow: 0 5px 10px 0 rgba(221, 221, 221, 0.75);
    font-family: "Roboto";
    font-size: 22px;
    position: relative;
    margin-bottom: 15px;
    text-align: left;
}

.prices__list__main:last-child {
    margin-bottom: 0;
}

.prices__list__main:hover {
    background: lighten($color_primary,20%);
}

.prices__list__main::after {
    position: absolute;
    content: '';
    top: 50%;
    right: 50px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(/img/service-arrow.png) center no-repeat;
    width: 15px;
    height: 22px;
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
}

.prices__list__main span {
    font-family: "Proxima Nova Bold";
    font-weight: bold;
}

.prices__list__main.active {
    background: $color_primary;
    color: white;
}

.prices__list__main.active::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
}

.prices__list__content {
    margin-left: 30px;
    display: none;
}

.prices__list__content__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    .price-btn {
        cursor: pointer;
        color: #b51726;
    }
}

.prices__list__content__item__left {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 50px;
    position: relative;
    margin-bottom: 10px;
    font-size: 17px;
    overflow: hidden;
    font-family: "Roboto";
    text-align: left;
}

.prices__list__content__item__left::after {
    display: inline-block;
    margin-right: -100%;
    content: '';
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed #a7a7a7;
    vertical-align: bottom;
}

.prices__list__content__item__left::before {
    position: absolute;
    content: '';
    left: 20px;
    top: 5px;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background-color: $color_primary;
}

.prices__list__content__item__left span {
    font-family: "Roboto";
    font-weight: bold;
}

.prices__list__content__item__right {
    padding-right: 30px;
    position: relative;
    text-transform: uppercase;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 15px;
}

.prices__list__content__item__right::after {
    content: '';
    position: absolute;
    right: 0;
    top: 2px;
    background: url(/img/arrow-right.png) no-repeat center;
    background-size: contain;
    width: 20px;
    height: 13px;
    vertical-align: baseline;
}

.prices__list__content__item__right:hover::after, .prices__list__content__item__right:focus::after {
    -webkit-animation: drag .5s ease infinite;
    animation: drag .5s ease infinite;
}

@media only screen and (max-width: 1000px) {
    .prices__list__main {
        font-size: 18px;
    }
}

@media only screen and (max-width: 800px) {
    .prices {
        padding-top: 20px;
    }
    .prices__list__main {
        font-size: 15px;
    }
    .prices__list__main:after {
        right: 10px;
    }
    .prices__list__content {
        margin-left: 0;
    }
    .prices__list__content__item__left {
        padding-left: 20px;
    }
    .prices__list__content__item__left:before {
        left: 5px;
        top: 7px;
    }
}

@media only screen and (max-width: 450px) {
    .prices__list__main {
        padding: 10px 35px 10px 20px;
    }
    .prices__list__content__item {
        padding-left: 12px;
    }
    .prices__list__content__item:before {
        left: 0;
    }
    .prices__list__content__item__right {
        width: auto;
    }
    .prices__list__content__item__left {
        width: 100%;
        margin-bottom: 5px;
    }
}