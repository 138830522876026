@import "global";
@import "blocks/header";
@import "blocks/video_block";
@import "blocks/breadcrumbs";
@import "blocks/services_menu";
@import "blocks/spesial_offers";
@import "blocks/video";
@import "blocks/pricelist";
@import "blocks/contacts";
@import "blocks/map";
@import "blocks/quest";
@import "blocks/footer";
@import "blocks/modal_callback";
@import "blocks/free_diagnostics";
@import "blocks/underprice_actions";
@import "blocks/other_models";
@import "blocks/mango";
@import "blocks/rating_schema";
@import "libs/stars";
