#breadcrumbs {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    padding: 12px 45px;
    line-height: 18px;
    background-color: $color_primary;
}

#breadcrumbs span,#breadcrumbs a {
    color: white;
    font-weight: bold;
}