.free_diagnostics{
  .zag {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin-top: 1em;
  }
  .content__white {
    background-color: #FFF;
    border-radius: 10px;
    padding: 20px 30px;
  }
  .content__white h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin: 15px 0;
  }
  ul.qm_style {
    list-style-type: none;
  }
  ul.qm_style li {
    padding-left: 20px;
    margin-top: 10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: calc(-30px/2);
      top: calc(50% - 1px);
      transform: translateY(-50%);
      width: 1.35rem;
      height: 1.35rem;
      background-image: url(/img/qm_li.svg);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}