.callback-popup {
    max-width: 400px;
}
.callback-popup-title {
    text-align: center;
    border-bottom: none;
}
.callback-popup-title h5 {
    width: 100%;
    text-transform: uppercase;
    color: #222220;
}
.callback-popup-body {
    padding: 0 14px;
}
.callback-popup-body p{
    color: #222220;
}
.callback-popup-body input{
    border: 1px solid #b51726;
}
.callback-popup-body input:focus{
    box-shadow: none;
    border: 1px solid #b51726;
}
.callback-popup-body select{
    border: 1px solid #b51726;
}
.callback-popup-body select:focus{
    box-shadow: none;
    border: 1px solid #b51726;
}
.callback-popup-footer {
    border-bottom: none;
    padding: 14px;
    text-align: center;
}
.callback-popup-footer button{
    width: 100%;
    background-color: #b51726;
    color: #fff;
}
.callback-popup-footer button:hover{
    background-color: #b51726;
    color: #fff;
    opacity: .9;
}
.callback-popup-footer button:focus{
    box-shadow: none;
}
.form-group input{
    margin-right: 10px;
}