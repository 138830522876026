.close-popup_perezvon {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/img/mango/close-btn.svg) 0 0 no-repeat;
  background-size: contain;
  z-index: 2200;
  top: -20px;
  right: -8px;
  transition: opacity .1s ease-out;
  cursor: pointer;
}

.callback-blok_perezvon {
  font-family: RobotoLight, Arial, Sans-serif, Helvetica;
  position: fixed;
  right: 1%;
  bottom: 2%;
  height: 75px;
  width: 568px;
  background: #e94742;
  box-shadow: 0 8px 10px #f78b7c;
  color: #fff;
  padding: 10px;
  border-radius: 30px;
  z-index: 2200;
}

.callback-blok_perezvon .title-widget_perezvon {

  display: none;
}

.callback-blok_perezvon p {
  background: unset !important;
  height: 68px !important;
  position: relative !important;
  overflow: hidden !important;
  margin-top: 8px !important;
  margin-left: 12px !important;
  color: #fff !important;
  line-height: 1.25 !important;
  font-size: 16px !important;
  text-overflow: clip !important;
  width: auto !important;
  height: auto !important;
  overflow: hidden !important;
  vertical-align: middle !important;

}

.callback-blok_perezvon span {
  height: 68px;

  position: absolute;
  overflow: hidden;
  margin-top: -48px;
  margin-left: 184px;
}

.callback-blok_perezvon span::before {
  font-weight: 700;
  font-size: 20px !important;
  content: attr(data-content);
  font-size: 22px;
  color: #fff;
  font-family: RobotoLight, Arial, Sans-serif, Helvetica;

  top: 27%;

  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  content: "+7";
  letter-spacing: 0;
}

.callback-blok_perezvon .flag-ru_perezvon {

  font-size: 0;
  width: 25px;
  height: 15px;
  background: url(/img/mango/flags.png) no-repeat;
  background-position: -200px -165px;
  display: inline-block;
  box-shadow: 0 0 0.5px #000;
  -webkit-box-shadow: 0 0 0.5px #000;
  -moz-box-shadow: 0 0 .5px #000;
  cursor: pointer;
  vertical-align: top;

  position: absolute;

  margin-top: -37px;
  margin-left: 146px;
}

.callback-blok_perezvon input {
  background: 0 0;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(255, 255, 255, .2);

  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
  font-family: RobotoLight, Arial, Sans-serif, Helvetica;
}

.callback-blok_perezvon input::placeholder {
  color: #fff;
}

.callback-blok_perezvon .button-call_perezvon {
  height: auto;
  width: auto;
  overflow: hidden;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  vertical-align: middle;
  border-radius: 45px;
  display: inline-block;
  background: #f78b7c no-repeat center;
  right: 11px;
  top: 11px;
}

.callback-bt_perezvon {

  background: #e94742;
  border: 2px solid #e94742;
  border-radius: 50%;
  box-shadow: 0 8px 10px #f78b7c;
  cursor: pointer;
  height: 68px;
  text-align: center;
  width: 68px;
  position: fixed;
  right: 1%;
  bottom: 2%;
  z-index: 999;
  transition: .3s;
  -webkit-animation: hoverWave linear 1s infinite;
  animation: hoverWave linear 1s infinite;
  -webkit-appearance: unset !important;;
}

.callback-bt_perezvon .text-call_perezvon {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  @include respond-below(xs) {
    height: 44px;
    width: 44px;
  }
}

.callback-bt_perezvon .text-call_perezvon span {
  text-align: center;
  color: #e94742;
  opacity: 0;
  font-size: 0;
  position: absolute;
  right: 4px;
  top: 22px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: opacity .3s linear;
  font-family: 'montserrat', Arial, Helvetica, sans-serif;
}

.callback-bt_perezvon .text-call_perezvon:hover span {
  opacity: 1;
  font-size: 11px;
  color: rgba(248, 120, 116, 1);
}

.callback-bt_perezvon:hover i {
  display: none;
}

.callback-bt_perezvon:hover {
  z-index: 1;
  background: #fff;
  color: transparent;
  transition: .3s;
}

.callback-bt_perezvon:hover i {
  color: #e94742;
  font-size: 40px;
  transition: .3s;
}

.callback-bt_perezvon i.mango__phone {
  height: 50px;
  width: 50px;
  transition: .5s ease-in-out;
  background: url("/img/mango/phone.svg") no-repeat;
  display: block;
  margin: 8px;
  @include respond-below(xs) {
    height: 34px;
    width: 34px;
    margin: 5px;
  }
}

.callback-bt_perezvon i {
  animation: 1200ms ease 0s normal none 1 running shake;
  animation-iteration-count: infinite;
  -webkit-animation: 1200ms ease 0s normal none 1 running shake;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes hoverWave {
  0% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 0 rgba(248, 120, 116, 0.5), 0 0 0 0 rgba(248, 120, 116, 0.5)
  }
  40% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 7px rgba(248, 120, 116, 0.5), 0 0 0 0 rgba(248, 120, 116, 0.5)
  }
  80% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 15px rgba(56, 163, 253, 0), 0 0 0 15px rgba(248, 120, 116, 0.67)
  }
  100% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 15px rgba(56, 163, 253, 0), 0 0 0 20px rgba(248, 120, 116, 0.00)
  }
}

@keyframes hoverWave {
  0% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 0 rgba(248, 120, 116, 0.5), 0 0 0 0 rgba(248, 120, 116, 0.5)
  }
  40% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 7px rgba(248, 120, 116, 0.5), 0 0 0 0 rgba(248, 120, 116, 0.5)
  }
  80% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 15px rgba(56, 163, 253, 0), 0 0 0 15px rgba(248, 120, 116, 0.67)
  }
  100% {
    box-shadow: 0 8px 10px #f78b7c, 0 0 0 15px rgba(56, 163, 253, 0), 0 0 0 20px rgba(248, 120, 116, 0.00)
  }
}

/* animations icon */

@keyframes shake {
  0% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    -webkit-transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
    -ms-transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    -webkit-transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
    -ms-transform: rotateZ(7.5deg);
    -webkit-transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
    -ms-transform: rotateZ(-6deg);
    -webkit-transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
    -ms-transform: rotateZ(5deg);
    -webkit-transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
    -ms-transform: rotateZ(-4.28571deg);
    -webkit-transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
    -ms-transform: rotateZ(3.75deg);
    -webkit-transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
    -ms-transform: rotateZ(-3.33333deg);
    -webkit-transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}

@-webkit-keyframes shake {
  0% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    -webkit-transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
    -ms-transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    -webkit-transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
    -ms-transform: rotateZ(7.5deg);
    -webkit-transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
    -ms-transform: rotateZ(-6deg);
    -webkit-transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
    -ms-transform: rotateZ(5deg);
    -webkit-transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
    -ms-transform: rotateZ(-4.28571deg);
    -webkit-transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
    -ms-transform: rotateZ(3.75deg);
    -webkit-transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
    -ms-transform: rotateZ(-3.33333deg);
    -webkit-transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}

@media (max-width: 575px) {
  .callback-bt_perezvon {
    width: 46px;
    height: 46px;
    bottom: 3%;
  }
  .callback-bt_perezvon i {
    font-size: 26px;
    line-height: 49px;
  }
  .callback-bt_perezvon .text-call_perezvon:hover span {
    font-size: 7px;
  }
  .callback-bt_perezvon .text-call_perezvon span {
    top: 9px;
    line-height: 11px;
  }
  .callback-blok_perezvon {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: unset;
    margin-left: unset;
    right: 0%;
    bottom: 0%;
    height: 220px;
    width: 98%;
    border-radius: 3px;
    text-align: center;
    z-index: 2200;
    min-height: 220px;
  }
  .close-popup_perezvon {
    margin-right: 10px;
    margin-top: 10px;
  }

  .callback-blok_perezvon p {
    position: unset;
    margin-top: unset;
    margin-left: unset;
  }

  .callback-blok_perezvon .flag-ru_perezvon {
    position: unset;
    margin-top: 10px;
    margin-left: unset;
  }
  .close-popup_perezvon {
    background: url(/img/mango/ic-cancel-24px.svg);
    width: 21px;
    height: 21px;
    top: 5px;
    right: 5px;
  }
  .callback-blok_perezvon h5 {
    font-size: 24px !important;
    margin-top: 37px;
    display: block;
    text-align: center;
    width: 100%;
  }

  .callback-blok_perezvon span {

    margin-top: unset;
    margin-left: unset;
  }
  .callback-blok_perezvon .button-call_perezvon {
    position: unset;
    position: relative;
    margin-top: unset;
    margin-top: 53px;
    margin-left: unset;
  }
}