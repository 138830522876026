.models{
  &__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    .img_wrapper{
      width: 190px;
      height: 140px;
      img{
        max-width: 100%;
        object-fit: cover;
      }
      a{
        text-align: center;
      }
    }
    /*.img_wrapper{
      margin: 0px 0px 20px 0px;
      padding: 0px 0px 100% 0px;
      overflow: hidden;
      position: relative;
      width: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }*/
  }
}
