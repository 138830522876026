.youtube_block{
  background-image: linear-gradient(-266deg, $color_primary 0, $color_primary_gradient 100%);
  color: #fff;
  box-sizing: border-box;
  padding: 20px;
  &__title {
    font-family: Roboto;
    font-size: 45px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 10px 0;
    text-align: center;
  }
  &__text{
    font-size: 16px;
    font-family: Roboto;
    text-align: center;
    margin-bottom: 25px;
  }
  &__video{
    width: 80%;
    margin: 0 auto;
  }
}