.video_block {
  color: #fff;
  height:687px;
  h1{
    font-size: 40px;
  }
  .content {
    z-index: 2;
    position: relative;
    margin-top: 150px;

    .zagolovki {
      text-align: center;
      color: #fff;
      font-size: 40px;
      font-weight: 600;
      text-shadow: 2px 2px 2px #000;
      padding-bottom: 20px;
      &.pb2 h4 {
        color: $color_primary;
        font-size: 34px;
        @include respond-below(sm){
          font-size: 30px;
        }
      }
      &.pb1{
        margin-top: 25px;
        padding-bottom: 0;
        h4 {
          font-size: 37px;
        }
      }
    }
  }
}

.utp-wrap {
  margin-top: 10px;

  .utp {
    width: 100%;
    margin-left: 8%;

    span {
      font-family: Roboto, Arial, sans-serif;
      display: block;
      color: #fff;
      font-size: 23px;
      margin-bottom: 25px;
      position: relative;
      text-shadow: 2px 2px 2px #000;
      padding: 0 35px;

      &:before {
        content: '';
        position: absolute;
        left: -40px;
        top: -4px;
        width: 50px;
        height: 50px;
      }
    }

    .utp1:before {
      background: url(/img/blocks/video_block/garantia.png) no-repeat center;
      background-size: cover;
    }

    .utp2:before {
      background: url(/img/blocks/video_block/deshevle.png) no-repeat center;
      background-size: cover;
    }

    .utp3:before {
      background: url(/img/blocks/video_block/sohranenie-garantii.png) no-repeat center;
      background-size: cover;
    }

    .utp4:before {
      background: url(/img/blocks/video_block/diagnostic.png) no-repeat center;
      background-size: cover;
    }
  }
}

.header_contact_form {
  display: flex;
  justify-content: center;

  input, button {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border-color: $color_primary;
    padding: 5px 10px;
  }

  input::placeholder {
    color: #ffffff;
    opacity: 0.8;
  }

  .mbtn:hover {
    background-color: $color_primary;
  }
}
#video-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-color: transparent;
  height: 787px;
  & > video {
    height: 837px;
    width: 100%;
    object-fit: cover;
    @include respond-below(sm){
      height:785px;
    }
  }
}

@media (min-width: 992px) {
  .video_block .content {
    padding-top: 100px;
  }
}

@media (max-width: 1199px) {
  .utp-wrap {
    .utp span {
      font-size: 19px;

      &:before {
        width: 40px;
        height: 40px;
        top: -5px;
        left: -30px;
      }
    }

    .block1form form {
      b {
        font-size: 20px;
      }

      span {
        font-size: 12px;
      }

      input {
        height: 50px;
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 991px) {
  .video_block {
    .header_contact_form {
      display: none;
    }
    .zagolovki.pb1 {
      display: none;
    }
    .block1form {
      display: none;
    }

    .utp-wrap .utp {
      width: 80%;
      margin-left: 15%;

      span {
        font-size: 23px;
        padding: 0 23px;

        &:before {
          left: -30px;
          top: -4px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .pb1 {
    margin-top: -15px;
  }
}

@media (max-width: 767px) {

  #video-bg {
    top: 52px;

    > video {
      object-fit: cover;
    }
  }

  .video_block .content {
    .zagolovki {
      font-size: 35px;
      padding-bottom: 0;

      h1 {
        font-size: 24px;
      }

      h4 {
        font-size: 22px;
      }
    }

    .pb1 h4 {
      font-size: 20px;
    }

    .utp-wrap {
      margin-top: 40px;

      .utp {
        margin-left: 0;
        width: 100%;

        span {
          font-size: 22px;

          &:before {
            width: 40px;
            height: 40px;
            top: -2px;
            left: -60px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .video_block .content {
    .zagolovki {
      font-size: 35px;
      padding-bottom: 0;

      h1 {
        font-size: 20px;
      }

      h4 {
        font-size: 19px;
      }
    }

    .pb1 h4 {
      font-size: 18px;
    }

    .utp-wrap .utp span {
      font-size: 19px;

      &:before {
        width: 35px;
        height: 35px;
        top: -2px;
        left: -50px;
      }
    }
  }
}

@media (max-width: 500px) {
  .video_block .content {
    .zagolovki {
      h1 {
        font-size: 19px;
      }

      h4 {
        font-size: 18px;
      }
    }

    .pb1 h4 {
      font-size: 17px;
    }

    .utp-wrap .utp {
      width: 95%;

      span {
        margin-left: 70px;
        font-size: 17px;

        &:before {
          left: -45px;
          top: -5px;
        }
      }
    }
  }
}

@media (max-width: 403px) {
  .video_block .content {
    .zagolovki {
      h1 {
        padding-bottom: 5px;
      }

      h4 {
        font-size: 18px;
      }
    }

    .pb1 {
      margin-top: -15px;
    }

    .utp-wrap .utp {
      span {
        line-height: 1.1;
        padding-bottom: 5px;

        &:before {
          top: -8px;
        }
      }

      .utp4:before {
        top: 0;
      }
    }
  }
}

@media (max-width: 353px) {
  .block4 .text-block1:before {
    top: 8px;
  }

  .video_block .content {
    margin-top: 100px;
  }
}

@media (max-width: 348px) {
  .video_block .content .utp-wrap .utp .utp3:before {
    top: 0;
  }
}

