.vazno {
  border-left: 5px solid $color_primary;
  padding-left: 15px;
}
.underprice_actions {
  font-size: 18px;
}
.action_sign_1 {
  font-size: 150%;
  color: $yellow;
  text-shadow: 1px 1px 1px #000;
}
.action_sign_2 {
  font-size: 150%;
  color: $color_primary;
  text-shadow: 1px 1px 1px #000;
}
.underprice{
  .mbtn{
    width: 100%;
    text-align: center;
    margin-top: 1em;
  }
}