@import "../global/variables";
#rating-box .star {
  font-size: x-large;
  width: 50px;
  display: inline-block;
  color: gray;
  position:relative;
    text-align: center;
  &:last-child {
    margin-right: 0;
  }
  &:before {
    content:'\2605';
  }
  &.on {
    color: white;
  }
  &.half:after {
      content: '\2605';
      color: white;
      position: absolute;
      margin-left: 0;
      width: 11px;
      left: 14px;
      overflow: hidden;
  }
}
@media (max-width: 1349px) {
  .rating_schema {
    margin-top: 10px;
  }
}
@media (min-width: 1350px) {
  .rating_schema {
    float: right !important;
  }
}