@import "~swiper/css/swiper.css";
.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 auto;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
  border-radius: 20px;
}