.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 8;

  .header-fix1 {
    background-color: #fff;
    text-align: center;
    padding-bottom: 4px;

    .header-logo {
      padding-top: 7px;

      img {
        max-width: 100%;
      }
    }

    .header-logo-social {
      margin-top: 3px;

      a {
        -webkit-transition: all .3s;
        transition: all .3s;

        &:hover {
          opacity: .8;
          -webkit-transition: all .3s;
          transition: all .3s;
        }
      }
    }

    .header-adres {
      font-size: 0.8rem;

      div {
        text-decoration: underline;
      }
    }

    .header-phone a {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      text-decoration: none;
    }
  }
}

.menubtn-wrap {
  display: none;
  text-align: right;

  .menubtn {
    cursor: pointer;

    img {
      width: 32px;
    }
  }
}

.header-menu {
  z-index: -2;
  .menu-wrap {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-around;
    position: relative;
    margin: 0;
  }
  &__item {
    text-align: center;
    padding: 5px 2px;
    list-style: none;
    min-width: 120px;
    -webkit-transition: all .3s;
    transition: all .5s;
    flex-grow: 1;
    &:hover {
      background-color: $color_primary;
    }

    a {
      text-decoration: none;
      color: #000;
    }

    &:hover a {
      color: #fff;
    }
    &_wide {
      flex-grow: 2;
      min-width: 150px !important;
    }
  }
}

.with_submenu {
  position: relative;
  &__menu {
    background-color: #fff;
    -webkit-transition: all .3s;
    transition: all .5s;
    position: absolute;
    z-index: -1;
    padding: 5px;
    transform: translateY(-100%);
    opacity: 0;
    width: 100%;
  }
  &:hover &__menu {
    transform: translateY(0);
    opacity: 1;
  }
  &__item {
    -webkit-transition: all .3s;
    transition: all .5s;
    list-style: none;
    text-align: left;
    padding: 2px 2px 2px 36px;
    cursor: pointer;
    margin: 5px;
    background-repeat: no-repeat;

    &:hover {
      color: #669966;
      filter: hue-rotate(90deg);
    }
  }
}

.header-menu1 {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
}

.header-menu2 {
  background-color: $color_primary;
  position: relative;

  .vip-menu-wrap {
    &:hover {
      .vip-menu-block {
        display: block;
      }
    }
    .vip-ssil {
      display: block;
      color: #fff;
      padding: 5px 20px;
      -webkit-transition: all .3s;
      transition: all .3s;

      &:hover {
        background-color: #ccc;
        color: #000;
        cursor: pointer;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
    }

    .vip-menu-block {
      background-color: #fff;
      position: fixed;
      right: 0;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      display: none;
      .img img {
        max-width: 100%;
      }
      .list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &__item{
          width: 33%;
          padding: 5px;
          @include respond-below(md){
            width: 50%;
          }
          a {
            display: block;
            width: 100%;
            text-align: left;
            border-bottom: .5px solid #ccc;
            color: $color_primary;
            text-decoration: none;
            padding-left: 10px;

            &:hover {
              background-color: $color_primary;
              color: #fff;
            }
          }
        }

      }
    }
  }
}

.mobile-menu-close {
  position: absolute;
  top: -19px;
  left: -3%;
  font-size: 42px;
  width: 100px;
  height: 100px;
  font-weight: bold;
  text-align: center;
  color: $red;
  cursor: pointer;
  display: none;
}

@media (max-width: 1199px) {
  .header .header-fix1 .header-adres div {
    display: inline;
  }

  .header-menu__item {
    min-width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .vip-menu-wrap {
    .vip-ssil {
      padding: 5px 10px;
    }

    .vip-menu-block {
      font-size: 14px;
    }
  }
}

@media (max-width: 991px) {
  .header3-whatsapp {
    display: none;
  }

  .header-fix1 {
    .header-adres {
      font-size: 11px !important;
    }

    .header-logo {
      text-align: left;
    }
  }

  .header-menu2 .vip-menu-wrap {
    font-size: 15px;

    .vip-ssil {
      padding: 5px 4px;
    }

    .vip-menu-block {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

@media (max-width: 767px) {
  .header {
    .header-fix1 {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #ccc;
      max-height: 100px;

      .header-logo {
        padding: 0;
        text-align: left;
        display: flex;
        justify-content: space-around;
        max-height: 30px;
      }

      .header-logo-img {
        text-align: left;

        img {
          width: 150px;
        }
      }

      .header-logo-social {
        display: none;
      }

      .header-phone {
        padding: 0;

        .mbtn {
          display: none;
        }
      }
    }

    .menubtn-wrap {
      display: block;
    }
  }

  .header-menu2 {
    background-color: #ccc;
    border-top: .5px solid grey;
    display: none;
    padding-top: 7px;
    padding-bottom: 40px;
    position: fixed;
    top: 87px;
    width: 100%;
    margin: 0;
    left: 0;

    .menu-wrap {
      display: block;
    }

    .vip-menu-wrap {
      font-size: 15px;

      .vip-ssil {
        display: block;
        text-align: center;
        padding: 7px 5px;
        color: #030303;
        font-size: 18px;
      }

      .vip-menu-block {
        font-size: 12px;
        line-height: 14px;
        top: 109px;
      }
    }
  }

  .vip-menu-wrap:hover .vip-menu-block {
    display: none;
  }

  .mobile-menu-close {
    display: block;
  }

}

@media (max-width: 575px) {

  .link_to_map {
    display: none;
  }

  .header .header-fix1 {
    .header-logo-img {
      padding-left: 10px;
      img {
        width: 106px;
      }
    }

    .header-phone a {
      font-size: 12px;
    }
  }

}

@media (max-width: 466px) {
  .header_contact_form {
    display: none;
  }

  .header-adres {
    padding-left: 3px;
  }
}
