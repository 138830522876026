.contacts {
  padding-bottom: 0;
  overflow: hidden;
}

.contacts_col--1 {
  min-height: 600px;
  padding-left: 100px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #120e1c;
  background-image: linear-gradient(-266deg, $color_primary 0, $color_primary_gradient 100%);
  color: #fff;
  box-sizing: border-box;
  @include respond-below(xs){
    padding-left: 10px;
  }
  a {
    display: block;
    color: #fff;
    text-decoration: underline;
  }
}
.contacts_col--2 {
  padding: 0;
}
.contacts_h3 {
  margin-bottom: 30px;
  font-size: 2.5rem;
}

.contacts_ul {
  padding-left: 26px;
  margin-bottom: 10px;
  font-size: 1.0625rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 30%;
    transform: translate(0, -50%);
    background-image: url(/img/blocks/contacts/map-point.png);
    width: 14px;
    height: 18px;
  }
  li{
    list-style: none;
  }
}

.contacts_tel {
  padding-left: 26px;
  margin-bottom: 10px;
  font-size: 1.1875rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url(/img/blocks/contacts/phone.png);
    width: 18px;
    height: 18px;
  }
}

.contacts_mail {
  padding-left: 26px;
  margin-bottom: 30px;
  font-size: .9375rem;
  text-decoration: underline;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url(/img/blocks/contacts/letter.png);
    width: 18px;
    height: 16px;
  }
}

.contacts_inp-cont {
  margin-bottom: 20px;
}

.contacts_inp {
  width: 50%;
  padding: 0 10px;
  max-width: 362px;
  height: 48px;
  background-color: #f1f6f9;
  border: none;
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
}
