.mbtn {
  background-color: $color_primary;
  color: #fff;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.mbtn:hover {
  opacity: .9;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.mbtn{
  display: inline-block;
  color: #fff;
  padding: 5px 12px;
}
.mbtn:hover{
  display: inline-block;
  text-decoration: none;
  &:hover{
    transform: translateY(1px);
    color: #fff!important;
  }
}

.primary-btn {
  display: inline-block;
  padding: 15px 50px;
  background: $color_primary linear-gradient(59deg, $color_primary 0, $color_primary_gradient 100%);
  box-shadow: 0 16px 35px rgba(0, 0, 0, .25);
  font-size: .75rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  transition: all .5s;
  &:hover{
    background: $color_primary linear-gradient(180deg, $color_primary 0, $color_primary_gradient 100%);
    transform: translateY(1px);
    color: #fff!important;
  }
}