.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow-y: auto;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    &.is-visible {
        visibility: visible;
        opacity: 1;

        .modal-container {
            transform: translateY(0);
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.85);
    }

    .modal-container {
        position: relative;
        width: 90%;
        max-width: 400px;
        background: #FFF;
        margin: 3em auto 4em;
        cursor: auto;
        border-radius: 0.25em;
        transform: translateY(-30px);
        transition-property: transform;
        transition-duration: 0.3s;
        @media only screen and (min-width: 600px) {
            margin: 10em auto;
        }

        .modal_close {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 3em;
            color: crimson;
            cursor: pointer;
        }

    }


}
.modal-form {
    padding: 1.4em;

    .fieldset {
        position: relative;
        margin: 1.4em 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    input, .form-submit {
        margin: 0;
        padding: 0;
        border-radius: 0.25em;
    }

    .full-width {
        width: 100%;
    }

    input {
        &.has-padding {
            padding: 12px 20px 12px 50px;
        }

        &.has-border {
            border: 1px solid #d2d8d8;

            &:focus {
                border-color: #343642;
                box-shadow: 0 0 5px rgba(52, 54, 66, 0.1);
                outline: none;
                transition: 0.3s;
            }
        }

        &.has-error {
            border: 1px solid #d76666;
        }
    }

    .modal-title {
        text-align: center;
        width: 100%;
        display: block;
        line-height: 120%;
        font-size: 120%;
    }

    .form-submit {
        padding: 16px 0;
        cursor: pointer;
        background: #b51726;
        color: #FFF;
        font-weight: bold;
        border: none;
        transition: 0.3s;
        text-transform: capitalize;
    }
    .form-msg{
        display: none;
        &__text{
            font-size: 22px;
            text-align: center;
        }
    }
}

@media only screen and (min-width: 600px) {
    .modal-form {
        padding: 2em;

        .fieldset {
            margin: 2em 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        input.has-padding {
            padding: 16px 20px 16px 50px;
        }

        .form-submit {
            padding: 16px 0;
        }
    }
}