.slick-prev,
.slick-next {
  width: 24px;
  height: 36px;
  position: absolute;
  cursor: pointer;
  display: block;
  transition: opacity .2s;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border:0;
  text-indent: -9999px;
  z-index: 2;
  border: none!important;
  outline: none!important;
  opacity:.7;
  &:hover, &:focus {
    opacity:1;
  }
}
.slick-prev {
  background: url(/img/slick-prev.svg) center no-repeat transparent;
  -webkit-background-size: cover;
  background-size: cover;
  left: 0;
}
.slick-next {
  background: url(/img/slick-next.svg) center no-repeat transparent;
  right: 0;
  -webkit-background-size: cover;
  background-size: cover;
}