.services_menu{
  background: white;
  z-index: 4;
  position: relative;
  margin-top: 0px;
  padding-top: 2rem;
  &__item {
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: .21875%;
    margin-right: .21875%;
    width: 19.5625%;
    margin-bottom: .4375%;
    display: block;
    font-size: 1rem;
    @include respond-below(lg){
      width: 24.5625%;
    }
    @include respond-below(md){
      width: 32.89583%;
    }
    @include respond-below(sm){
      width: 49.5625%;
    }
    @include respond-below(xs){
      width: 99.5625%;
    }
    &_hidden{
      display: none;
    }
    &:hover{
      .services_menu__caption {
        background-color: $color_primary;
      }
      .services_menu__image {
        transform: translate(-50%, -50%) scale(1.1)
      }
    }
  }
  &__image_wrapper {
    padding-top: 70%;
    position: relative;
    overflow: hidden;
    &:after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .4;
      z-index: 2;
      cursor: pointer;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all .2s linear;
    cursor: pointer;
  }

  &__caption {
    width: 70%;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: rgba(0,0,0,.6);
    font-size: .625rem;
    color: #fff;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%,0);
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 3;

  }
}